import Home from "components/auth/Home";
import LoginForm from "components/home/LoginForm";
import React from "react";
export default function Login() {
  return (
    <Home>
      <LoginForm />
    </Home>
  )
}
