import { doc, getDoc, collection, getDocs, where, query, limit } from "firebase/firestore";
import { firestore } from "FirebaseHelper/FirebaseInit"
import { School, UserData } from "./FirebaseTypes";

export const getUserData = async (uid: string) => {
  console.log(uid)
  const doc = getUserDoc(uid);
  const data = await getDoc(doc);
  console.log(data.data())
  return data.data() as UserData
}

const getUserDoc = (uid: string) => {
  return doc(firestore, `Users/${uid}`);
};

const getSchoolCollection = () => {
  return collection(firestore, "/Schools")
}

export const getSchoolsByRegion = async (region: string) => {
  const coll = getSchoolCollection();
  const q = query(coll, where("REGIONE", "==", region.toUpperCase()))
  const docs = await getDocs(q)
  return docs.docs.map(d => d.data() as School)
}

export const getSchoolsByProvince = async (province: string) => {
  const coll = getSchoolCollection();
  const q = query(coll, where("PROVINCIA", "==", province.toUpperCase()))
  const docs = await getDocs(q)
  return docs.docs.map(d => d.data() as School)
}

export const getSchoolsByCode = async (code: string) => {
  const coll = getSchoolCollection();
  const q = query(coll, where("CODICESCUOLA", "==", code.toUpperCase()), limit(1))
  const docs = await getDocs(q)
  if (docs.size == 1)
    return [docs.docs[0].data() as School]
  return []
}

export const getSchoolsByName = async (name: string) => {
  const coll = getSchoolCollection();
  const q = query(coll, where("DENOMINAZIONESCUOLA", "==", name.toUpperCase()))
  const docs = await getDocs(q)
  return docs.docs.map(d => d.data() as School)
}
