import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./UserSlice"
import authReducer from "./AuthSlice"
const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
  }
})// Infer the `RootStat
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
