const Errors = {
  MISSING_EMAIL: {
    message: "Inserire la mail",
    code: "auth/missing-email"
  },
  EMAIL_ALREADY_EXISTS: {
    message: 'La mail inserita è già associata ad un account.',
    code: 'auth/email-already-exists',
  },
  EMAIL_NOT_VALID: {
    message: 'La mail inserita non è valida.',
    code: 'auth/invalid-email',
  },
  PASSWORD_NOT_VALID: {
    message: 'La password inserita non è valida.',
    code: 'auth/wrong-password',
  },
  EMAIL_NOT_FOUND: {
    message: 'Nessun utente associato a questa email.',
    code: 'auth/user-not-found',
  },
  SCHOOL_NOT_FOUND: {
    message: 'La scuola inserita non è stata trovata o non ha acquistato la licenza per il software',
    code: 'school-not-found'
  },
  INTERNAL_ERROR: {
    message: "L'applicazione ha riscontrato un errore interno, contattare l'assistenza per risolvere.",
    code: 'internal-error'
  },
};
type FirebaseError = {
  message: string,
  code: string
}
const ErrorsTypes = new Map([
  ['auth/missing-email', Errors.MISSING_EMAIL],
  ['auth/email-already-exists', Errors.EMAIL_ALREADY_EXISTS],
  ['auth/invalid-email', Errors.EMAIL_NOT_VALID],
  ['auth/wrong-password', Errors.PASSWORD_NOT_VALID],
  ['auth/user-not-found', Errors.EMAIL_NOT_FOUND],
  ['school-not-found', Errors.SCHOOL_NOT_FOUND],
]);
function getError(errorCode: string): FirebaseError {
  return ErrorsTypes.has(errorCode) ? ErrorsTypes.get(errorCode)!! : Errors.INTERNAL_ERROR
}
function getErrorMessage(errorCode: string): string {
  return ErrorsTypes.has(errorCode) ? ErrorsTypes.get(errorCode)!!.message : Errors.INTERNAL_ERROR.message
}
export { Errors, ErrorsTypes, getError, getErrorMessage }
