import Home from "components/auth/Home";
import LoginForm from "components/home/LoginForm";
import SigninForm from "components/home/SigninForm";
import React from "react";
export default function Register() {
  return (
    <Home>
      <SigninForm />
    </Home>
  )
}
