import React from "react";
import logo from "assets/verse-logo.svg";
import visor from "assets/verse-man.svg";
export default function Welcome(props: any) {
  return (
    <div className="mx-auto px-4">
      <div className="row  mb-3">
        <div className="col text-center">
          <img
            src={visor}
            className="w-1/2 opacity-25 inline "
            alt="man with visor"
          />
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col text-center">
          {" "}
          <img src={logo} className="w-1/2 inline-block" alt="verse logo" />
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col text-center">
          {" "}
          <h1 className=" text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-l from-electric-blue  to-soft-purple">
            Bid Portal
          </h1>
        </div>
      </div>

      <div className="row">
        <div className=" col text-1xl text-justify text-white">
          <p>
            Il Bid portal di &nbsp;
            <a
              href="https://dotslot.it/"
              target="_blank"
              className="underline decoration-electric-blue hover:decoration-soft-purple"
              rel="noreferrer"
            >
              Dotslot
            </a>
            &nbsp;è lo strumento operativo nato per la gestione delle rete
            vendite, un sistema semplice per i nostri dealer e reseller per
            segnalare le trattative, lavorare sulle anagrafiche e confermare gli
            ordini.
          </p>
          <p className="mt-3">
            Il portale vi supporterà costantemente mettendo al vostro servizio
            strumenti mirati che possano garantire la massima le risposte
            adeguate alle esigenze dei clienti finali.
          </p>
        </div>
      </div>
    </div>
  );
}
