import React from "react";
import logo from "assets/verse-logo.svg";
import { SidebarButton } from "./BidUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "ReduxHelper/AuthSlice";
import {
  faHome,
  faRightFromBracket,
  faGear,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "ReduxHelper/Hooks";

const iconHome = <FontAwesomeIcon className="mr-1" icon={faHome} />;
const iconQuit = <FontAwesomeIcon className="mr-1" icon={faRightFromBracket} />;
const iconSettings = <FontAwesomeIcon className="mr-1" icon={faGear} />;
const iconRecap = <FontAwesomeIcon className="mr-1" icon={faFileLines} />;

function doingSomething() {
  alert("I'm doing something!");
}

export default function Sidebar(props: any) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <div className="fixed min-h-screen w-72 bg-lucky-point ">
      <div className="row">
        <div className="col text-center">
          <img src={logo} alt="verse logo" className="mt-6 w-1/8 inline" />
        </div>
      </div>
      {/* SideBar Menu Top */}
      <nav className="flex flex-col justify-center items-center w-full mt-6">
        <SidebarButton
          label="Home"
          icon={iconHome}
          action={() => navigate("/home")}
        />
        <SidebarButton
          label="Storico Bid"
          icon={iconRecap}
          action={() => navigate("/bidhistory")}
        />
      </nav>
      {/* SideBar Menu Bottom */}
      <nav className="absolute bottom-6 left-0 flex justify-center items-center w-full mt-6">
        <SidebarButton label="Esci" icon={iconQuit} action={() => dispatch(logout())} />
      </nav>
    </div>
  );
}
