import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData, DEFAULTUSER } from 'FirebaseHelper/FirebaseTypes';
import { getUserData, } from 'FirebaseHelper/FirestoreHelper';
import { AppDispatch, RootState } from './Store';

const initialState: UserData = {
  ...DEFAULTUSER,
};
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    addUser: (state, action: PayloadAction<UserData>) => ({ ...state, ...action.payload }),
  },
});

export const { addUser, } = userSlice.actions;
export const loadUser = (uid: string) => (dispatch: AppDispatch) => {
  getUserData(uid).then(user => {
    dispatch(addUser({ ...user, uid }));
  });
};
export default userSlice.reducer;
