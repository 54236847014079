import React, { useState } from "react";
import logo from "assets/verse-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Greetings,
  SectionLabel,
  SidebarButton,
  MenuButton,
  BidItem,
  SearchButton,
  BasketButton,
} from "components/ui/BidUI";

import {
  faDownload,
  faHome,
  faUpload,
  faRightFromBracket,
  faGear,
  faFileLines,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "ReduxHelper/Hooks";
import {
  getSchoolsByCode,
  getSchoolsByName,
  getSchoolsByProvince,
  getSchoolsByRegion,
} from "FirebaseHelper/FirestoreHelper";
import { functions } from "FirebaseHelper/FirebaseInit";
import { School, UserData } from "FirebaseHelper/FirebaseTypes";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import Sidebar from "components/ui/SideBar";
import { loadUser } from "ReduxHelper/UserSlice";
import { getBandoUrl, getGraduatorieUrl } from "FirebaseHelper/StorageHelper";
import { FileUploader } from "react-drag-drop-files";
import { ReadCSVFile } from "tools/FileUploader";

const iconDownload = <FontAwesomeIcon className="mr-1" icon={faDownload} />;
const iconUpload = <FontAwesomeIcon className="mr-1" icon={faUpload} />;
const iconHome = <FontAwesomeIcon className="mr-1" icon={faHome} />;
const iconQuit = <FontAwesomeIcon className="mr-1" icon={faRightFromBracket} />;
const iconSettings = <FontAwesomeIcon className="mr-1" icon={faGear} />;
const iconRecap = <FontAwesomeIcon className="mr-1" icon={faFileLines} />;
const iconShop = <FontAwesomeIcon className="mr-1" icon={faShoppingBasket} />;

function doingSomething() {
  alert("Ancora non disponibile");
}

export default function BidMain() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((s) => s.user);
  const navigate = useNavigate();
  const [searchFunction, setSearchFunction] = useState<
    ((arg0: string) => any) | null
  >(null);
  const [searchInput, setSearchInput] = useState("");
  const search = async () => {
    if (!searchFunction) return;
    let a = await searchFunction(searchInput);
    setSchools(a);
  };
  const [schools, setSchools] = useState([] as School[]);
  const [toBid, setToBid] = useState<null | School>(null);
  const [clientCode, setClientCode] = useState<string>("");
  const Bid = () => {
    if (!toBid) {
      return;
    }
    var bidSchool = httpsCallable(functions, "bidSchool");
    const data = {
      school: toBid.CODICESCUOLA,
      user: user,
      codice_cliente: clientCode,
    };
    bidSchool(data)
      .catch((e) => console.log(e))
      .then(() => alert("Bid avvenuto con successo"));
    setToBid(null);
    setSchools([]);
  };
  const download = async (title: string) => {
    let url: string;
    if (title == "bando") url = await getBandoUrl();
    else url = await getGraduatorieUrl();
    var link = document.createElement("a");
    link.download = title + ".pdf";
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main className="min-h-screen min-w-full">
      {/* SideBar */}
      <Sidebar />
      {/*  Content */}
      <div className="min-h-screen pl-72 w-full bg-lucky-point-100 ">
        {/* Top  Menu */}
        <nav className="fixed right-0 left-72 justify-between flex items-center h-16 bg-lucky-point-800">
          <div>
            <Greetings name={user.name} />
          </div>

          <nav className="mr-6">
            <MenuButton
              label="Scarica Graduatorie"
              icon={iconDownload}
              action={() => download("graduatorie")}
            />
            <MenuButton
              label="Scarica il bando"
              icon={iconDownload}
              action={() => download("bando")}
            />
          </nav>
        </nav>
        <div className="p-10 pt-16">
          <SectionLabel label="Seleziona un prodotto" />
          {/* Products */}
          <div className="flex flex-row">
            <BidItem
              title="Bando scuola infanzia"
              description="Kid Verse è un sistema didattico esperienziale, articolato in unità di apprendimento mirate a sviluppare nei bambini attenzione, relazione, gioco, autonomia e creatività."
            />
          </div>

          <div className=" mt-6">
            <SectionLabel label="Ricerca Istituto" />

            <div className="flex flex-row">
              <input
                type="text"
                id="search-school"
                className=" basis-auto grow shadow appearance-none border rounded  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <nav className="basis-auto mr-6">
                <SearchButton
                  label="Codice Istituto"
                  action={() =>
                    setSearchFunction(
                      () => (code: string) => getSchoolsByCode(code)
                    )
                  }
                  active={
                    searchFunction
                      ? searchFunction.toString().indexOf("getSchoolsByCode") >
                      0
                      : false
                  }
                />
                <SearchButton
                  label="Nome Istituto"
                  active={
                    searchFunction
                      ? searchFunction.toString().indexOf("getSchoolsByName") >
                      0
                      : false
                  }
                  action={() =>
                    setSearchFunction(
                      () => (code: string) => getSchoolsByName(code)
                    )
                  }
                />
              </nav>
            </div>
            <div className="w-full flex flex-row justify-center mt-4">
              {searchFunction != null ? (
                <button
                  onClick={search}
                  className="h-8 px-4 my-2 justify-self-center self-center text-lucky-point transition-colors duration-200 bg-transparent border-lucky-point-500 border-2 rounded-md  hover:bg-lucky-point-800 hover:text-lucky-point-200 "
                >
                  Cerca Istituto
                </button>
              ) : null}
            </div>
          </div>
          {schools.map((s) => (
            <SchoolElement
              school={s}
              key={s.CODICESCUOLA}
              setBid={(sc: School | null) => setToBid(sc)}
            />
          ))}

          <div className="w-full text-center pt-6 pb-3 flex flex-row justify-center">
            {toBid ? (
              <>
                <input
                  type="text"
                  id="search-school"
                  className=" row shadow  border rounded px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Codice cliente opzionale"
                  onChange={(e) => setClientCode(e.target.value)}
                />
                <BasketButton
                  label="Fai un bid per la scuola selezionata"
                  icon={iconShop}
                  action={Bid}
                />
              </>
            ) : null}
          </div>
        </div>
        <DragDrop user={user} />
      </div>
    </main>
  );
}
const SchoolElement = ({
  school,
  setBid,
}: {
  school: School;
  setBid: Function;
}) => {
  return (
    <div className="rounded-lg bg-lucky-point py-3  mt-6 shadow-md shadow-gray-700">
      <table className="w-full table-auto border-collapse text-lucky-point-200">
        <thead>
          <tr>
            <th className="py-3">Scegli</th>
            <th>Isituto</th>
            <th>Codice Istituto</th>
            <th>Provincia</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-collapse border-y border-lucky-point-700 text-center">
            {Object.keys(school.verseData).length == 0 ? (
              <td className="py-2">
                <input
                  type="checkbox"
                  className="align-middle"
                  onChange={(e) =>
                    e.target.checked ? setBid(school) : setBid(null)
                  }
                />
              </td>
            ) : (
              <td />
            )}
            <td>{school.DENOMINAZIONESCUOLA}</td>
            <td>{school.CODICESCUOLA}</td>
            <td>{school.PROVINCIA}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function DragDrop({ user }: { user: UserData }) {
  const fileTypes = ["csv"];
  const handleChange = (file: File) => {
    ReadCSVFile(file, user);
  };
  return (
    <div className="flex-1 justify-center flex-row align-center m-auto h-96">
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        label="Clicca per scegliere il file csv oppure fai drag & drop"
        classes="m-auto h-96"
      />
    </div>
  );
}
