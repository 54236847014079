import React, { useEffect } from "react";
import "./index.css";
import BidMain from "./screens/BidMain";
import Login from "screens/Auth/Login";
import { useAppDispatch, useAppSelector } from "ReduxHelper/Hooks";
import { Navigate, Route, Routes } from "react-router-dom";
import Register from "screens/Auth/Register";
import { authChange } from "ReduxHelper/AuthSlice";
import BidHistory from "screens/BidHistory";
import NotVerified from "screens/NotVerified";

function App() {
  const auth = useAppSelector((s) => s.auth);
  const verified = useAppSelector(s => s.user.verified)
  const dispatch = useAppDispatch();
  useEffect(() => dispatch(authChange(auth)), []);
  return (
    <>
      {auth.logged ?
        verified ? (
          <Routes>
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="/home" element={<BidMain />} />
            <Route path="/bidhistory" element={<BidHistory />} />
          </Routes>
        )
          :
          <Routes >
            <Route path="*" element={<NotVerified />} />
          </Routes>
        : (
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        )}
    </>
  );
}

export default App;
