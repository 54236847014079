import LoginForm from "components/home/LoginForm";
import SigninForm from "components/home/SigninForm";
import Welcome from "components/home/Welcome";
import React from "react";

export default function Home({ children }: { children: React.ReactNode }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-lucky-point  to-soft-purple">
      <div className="grid grid-cols-1  md:grid-cols-2 gap-4 p-5 ">
        <Welcome />
        {children}
      </div>
    </div>
  );
}
