import { auth, functions } from "./FirebaseInit";
import {
  signInWithEmailAndPassword,
  User,
  onAuthStateChanged,
  signOut as so
} from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
  httpsCallableFromURL,
} from "firebase/functions";
import { AuthData, UserData } from "./FirebaseTypes";

export const login = async (user: AuthData) => {
  return signInWithEmailAndPassword(auth, user.email, user.password)
    .then((u) => u.user)
    .catch((e) => {
      throw new Error(e.code)
    }
    );
};
export const signUp = async (user: UserData) => {
  var createUser = httpsCallable(functions, "createUser");
  const data = { user: user }
  return createUser(data)
    .then((res: any) => {
      return login({ email: user.email, password: user.password! });
    })
    .catch((e) => {
      throw new Error(e.message);
    });
}
export const userListener = (setUser: (arg0: string | null) => void) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user.uid)
    }
    else
      setUser(null)
  })
};
export const signOut = () => so(auth)
