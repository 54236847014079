import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULTUSER, UserData } from "FirebaseHelper/FirebaseTypes";
import {
  login as LogIn,
  signUp,
  userListener,
  signOut,
} from "FirebaseHelper/FirebaseAuthHelper";
import { getUserData } from "FirebaseHelper/FirestoreHelper";
import { getErrorMessage } from "FirebaseHelper/ErrorHandler";
import { RootState } from "./Store";
import { loadUser } from "./UserSlice";
type Key =
  | "name"
  | "surname"
  | "address"
  | "business"
  | "city"
  | "cap"
  | "IVA"
  | "CODE"
  | "email"
  | "password"
  | "uid";
interface Payload {
  key: Key;
  value: string;
}
interface AuthSliceType {
  logged: boolean;
  error: string;
  data: UserData;
  uid: string;
}
const initialState: AuthSliceType = {
  logged: false,
  error: "",
  data: DEFAULTUSER,
  uid: "",
};
export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addField: {
      reducer(state, action: PayloadAction<Payload>) {
        return {
          ...state,
          data: { ...state.data, [action.payload.key]: action.payload.value },
        };
      },
      prepare(key: Key, value) {
        return {
          payload: { key, value },
        };
      },
    },
    setError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
    }),
    removeError: (state) => (state = { ...state, error: "" }),
    setLogged: (state, action: PayloadAction<string>) => ({
      ...state,
      logged: true,
      uid: action.payload,
    }),
    reset: () => initialState,
  },
});
export const { addField, setError, removeError, setLogged, reset } =
  AuthSlice.actions;
export const login =
  (email: string, password: string) => (dispatch: Function) => {
    LogIn({ email, password })
      .then((u) => dispatch(addField("uid", u.uid)))
      .catch((e) => dispatch(setError(getErrorMessage(e.message))));
  };
export const register = (data: UserData) => (dispatch: Function) => {
  const keys: Key[] = [
    "name",
    "surname",
    "address",
    "business",
    "city",
    "cap",
    "IVA",
    "CODE",
    "email",
    "password",
  ];

  for (const k of keys) {
    if (data[k]?.length == 0) {
      console.log(k)
      dispatch(setError("Tutti i campi devono essere compilati"));
      return;
    }
  }

  signUp(data)
    .then((u) => dispatch(addField("uid", u.uid)))
    .catch((e) => dispatch(setError(getErrorMessage(e.message))));
};
export const logout = () => (dispatch: Function) => {
  signOut();
};
export const authChange =
  (currentAuth: AuthSliceType) => (dispatch: Function) => {
    userListener((uid) => {
      if (!uid) {
        dispatch(reset());
        return;
      }
      if (currentAuth.logged && uid === currentAuth.uid) {
        return;
      }
      dispatch(loadUser(uid));
      dispatch(setLogged(uid));
    });
  };
export const selectLogged = (state: RootState) => state.auth.logged;
export default AuthSlice.reducer;
