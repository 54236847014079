import { Greetings, SectionLabel } from "components/ui/BidUI";
import Sidebar from "components/ui/SideBar";
import React from "react";
import { useAppSelector } from "ReduxHelper/Hooks";
export default function NotVerified() {
  const user = useAppSelector(s => s.user)
  return (
    <main className="min-h-screen min-w-full">
      {/* SideBar */}
      <Sidebar />
      {/*  Content */}
      <div className="min-h-screen pl-72 w-full bg-lucky-point-100 ">
        {/* Top  Menu */}
        <nav className="fixed right-0 left-72 justify-between flex items-center h-16 bg-lucky-point-800">
          <div>
            <Greetings name={user.name} />
          </div>
        </nav>
        <div className="p-10 pt-16">
          <div className=" mt-6">
            <SectionLabel label="Il suo account è in fase di verifica. Attendere fino a 3 giorni." />
          </div>

        </div>
      </div>
    </main>
  )
}
