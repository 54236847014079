import { parse } from "papaparse";
import { functions } from "FirebaseHelper/FirebaseInit";
import { httpsCallable } from "firebase/functions";
import { UserData } from "FirebaseHelper/FirebaseTypes";
type CSVDATA = {
  CODICE_CLIENTE: string;
  MECCANOGRAFICO: string;
};
export function ReadCSVFile(file: File, user: UserData) {
  const conf = {
    complete: (res: any, f: any) => {
      if (res.errors.lenght > 0) {
        alert("Formato file non compatibile")
        return
      }
      console.log(res)
      bidSchools(res.data, user);
    },
    header: true,
    skipEmptyLines: true,
  };
  console.log("here1")
  parse(file, conf)
}
//bidSchoolFromFile
const bidSchools = (data: CSVDATA[], user: UserData) => {
  console.log("here")
  var bidSchool = httpsCallable(functions, "bidSchoolFromFile");
  const schools = data.map(d => ({ codice_cliente: d.CODICE_CLIENTE, school: d.MECCANOGRAFICO }))
  const payload = {
    schools,
    user
  };
  bidSchool(payload)
    .catch((e) => console.log(e))
    .then(() => alert("Bid avvenuto con successo"));
};
