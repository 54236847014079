import { initializeApp, } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const isDev = () =>
(!process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "test")
const firebaseConfig = {
  apiKey: "AIzaSyDLrGeDYB1a0XeS4aMUJ5hwSEdo62gmvkk",
  authDomain: "verse-bid-79bc8.firebaseapp.com",
  projectId: "verse-bid-79bc8",
  storageBucket: "verse-bid-79bc8.appspot.com",
  messagingSenderId: "431378100524",
  appId: "1:431378100524:web:b125b7f495d581e303fc89",
  measurementId: "G-G08ZZBBB9S",
};
const testConfig = { projectId: "demo-test", apiKey: "none", storageBucket: "verse-bid-79bc8.appspot.com" };
const app = initializeApp(isDev() ? testConfig : firebaseConfig);
const firestore = getFirestore();
const auth = getAuth();
const functions = getFunctions(app, "europe-west1")
const storage = getStorage()
if (isDev()) {
  connectAuthEmulator(auth, `http://127.0.0.1:9099`);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(firestore, "localhost", 8080);
}
// Initialize Firebase
export { firestore, auth, functions, storage };
