import React from "react";
import { Greetings, SectionLabel } from "components/ui/BidUI";
import Sidebar from "components/ui/SideBar";
import { Bid } from "FirebaseHelper/FirebaseTypes";
import { useAppSelector } from "ReduxHelper/Hooks";

export default function BidHistory(props: any) {
  const bids = useAppSelector((s) => s.user.bids);
  return (
    <main className="min-h-screen min-w-full">
      {/* SideBar */}
      <Sidebar />
      {/*  Content */}
      <div className="min-h-screen pl-72 w-full bg-lucky-point-100 ">
        {/* Top  Menu */}
        <nav className="fixed right-0 left-72 justify-between flex items-center h-16 bg-lucky-point-800">
          <div>
            <Greetings name={props.loggedUser} />
          </div>
        </nav>
        <div className="p-10 pt-16">
          <div className=" mt-6">
            <SectionLabel label="Storico BID" />
          </div>

          {/* User Bids Results */}
          <BidTable bids={bids} />
        </div>
      </div>
    </main>
  );
}
const BidTable = ({ bids }: { bids: Bid[] }) => {
  return (
    <div className="rounded-lg bg-lucky-point py-3  mt-6 shadow-md shadow-gray-700">
      <table className="w-full table-auto border-collapse text-lucky-point-200">
        <thead>
          <tr>
            <th>Prodotto</th>
            <th>Codice Istituto</th>
            <th>Codice Cliente</th>
            <th>Data di inserimento</th>
            <th>Data di scadenza</th>
          </tr>
        </thead>
        <tbody>
          {bids.map((b, i) => <BidRow bid={b} key={i} />)}
        </tbody>
      </table>
    </div>
  );
};
const BidRow = ({ bid }: { bid: Bid }) => {
  return (
    <tr className="border-collapse border-y border-lucky-point-700 text-center">
      <td>{bid.product}</td>
      <td>{bid.school}</td>
      <td>{bid.codice_cliente}</td>
      <td>{bid.date}</td>
      <td>{bid.expireDate}</td>
    </tr>
  );
};
