import { storage } from "FirebaseHelper/FirebaseInit";
import { getDownloadURL, ref } from "firebase/storage";

export const getBandoUrl = () => {
  return getDownloadURL(ref(storage, "documents/bando.pdf"))
    .then((url) => url)
    .catch((error) => {
      // Handle any errors
      console.log(error);
      return ""
    });
};

export const getGraduatorieUrl = () => {
  return getDownloadURL(ref(storage, "documents/graduatorie.zip"))
    .then((url) => url)
    .catch((error) => {
      // Handle any errors
      console.log(error);
      return ""
    });
};
