export type UserData = {
  name: string,
  surname: string,
  address: string,
  city: string,
  cap: string,
  business: string,
  IVA: string,
  CODE: string,
  email: string,
  password?: string,
  uid?: string
  verified: boolean,
  bids: Bid[]
}
export const DEFAULTUSER = {
  name: "",
  surname: "",
  address: "",
  city: "",
  cap: "",
  business: "",
  IVA: "",
  CODE: "",
  email: "",
  verified: false,
  bids: []
}
export interface Bid {
  school: string,
  status: BidStatus
  product: Product
  date: string,
  expireDate: string
  codice_cliente: string
}
type BidStatus = "NON INVIATO" | "IN ATTESA" | "COMPLETATO"
export interface AuthData {
  email: string,
  password: string,
}
export type VerseData = {
  [p in Product]: string
}
export type Product = "KIDVERSE" | "Product2"
export interface School {
  ANNOSCOLASTICO: string,
  AREAGEOGRAFICA: string,
  REGIONE: string,
  PROVINCIA: string,
  CODICEISTITUTORIFERIMENTO: string,
  DENOMINAZIONEISTITUTORIFERIMENTO: string,
  CODICESCUOLA: string,
  DENOMINAZIONESCUOLA: string,
  INDIRIZZOSCUOLA: string,
  CAPSCUOLA: string,
  CODICECOMUNESCUOLA: string,
  DESCRIZIONECOMUNE: string,
  DESCRIZIONECARATTERISTICASCUOLA: string,
  DESCRIZIONETIPOLOGIAGRADOISTRUZIONESCUOLA: string,
  INDICAZIONESEDEDIRETTIVO: string,
  INDICAZIONESEDEOMNICOMPRENSIVO: string,
  INDIRIZZOEMAILSCUOLA: string,
  INDIRIZZOPECSCUOLA: string,
  SITOWEBSCUOLA: string,
  SEDESCOLASTICA: string,
  verseData: VerseData
}
